<div class="bgimg">
  <div class="topleft">
    <img src="../assets/massol.jpg"/>
  </div>
  <div class="middle">
    <h1>EM BREVE<br> Uma Nova MASSOL</h1>
    <hr>
    <p style="font-size: 35px;">O SITE ESTÀ QUASE PRONTO</p>

    <p style="font-size: 24px;">
      BR 470 km 66 número 2200<br>
      Encano do Norte - Indaial SC<br>
      CEP: 89085-060<br><br>
      atendimento@massol.ind.br<br>
      Tel: ( 47 ) 3394-7070

    </p>

    <div style="display: inline-block; margin-right: 10px; margin-top: 25px;">
      <div class="fa-icon" style="margin-bottom: 14px;">
        <a href="https://www.facebook.com/massol.combr.7"><i class="fa fa-facebook-f" style="color: white; font-size: 22px; margin-top: 4px;"></i></a>
      </div>
    </div>
    <div style="display: inline-block">
      <div class="fa-icon" >
        <a href="https://api.whatsapp.com/send?phone=5547999549492" target="_blank"><i class="fa fa-whatsapp" style="color: white; font-size: 22px; margin-top: 4px;"></i></a>
      </div>
    </div>
    
    
    
  </div>
  <div class="bottom">
    <p>Em desenvolvimento por <a href="http://dgsys.com.br/">DG Sistemas</a></p>
  </div>
</div>